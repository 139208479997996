<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Identitas perusahaan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Identitas perusahaan</span>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-12">
        <div class="card bg-white pt-4">
          <form @submit.prevent="handleSubmit(!v$.$invalid)">
            <div class="grid">
              <div class="col-12 md:col-12">
                <div class="formgrid grid">
                  <div class="field col-6">
                    <label>Nama perusahaan</label>
                    <InputText
                      v-model="form.nama"
                      type="text"
                      :class="{
                        'p-invalid': v$.form.nama.$invalid && submitted,
                      }"
                      maxlength="150"
                      class="w-full"
                    />
                    <small
                      v-if="
                        (v$.form.nama.$invalid && submitted) ||
                        v$.form.nama.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.nama.required.$message }}</small
                    >
                  </div>
                  <div class="field col-3">
                    <label>Telepon 1</label>
                    <InputText
                      v-model="form.telepon_1"
                      type="text"
                      maxlength="150"
                      class="w-full"
                      :class="{
                        'p-invalid': v$.form.telepon_1.$invalid && submitted,
                      }"
                    />
                    <small
                      v-if="
                        (v$.form.telepon_1.$invalid && submitted) ||
                        v$.form.telepon_1.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.telepon_1.required.$message }}</small
                    >
                  </div>
                  <div class="field col-3">
                    <label>Telepon 2</label>
                    <InputText
                      v-model="form.telepon_2"
                      type="text"
                      maxlength="150"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-3">
                    <label>Email Perusahaan</label>
                    <InputText
                      v-model="form.email_perusahaan"
                      type="text"
                      maxlength="150"
                      class="w-full"
                      :class="{
                        'p-invalid':
                          v$.form.email_perusahaan.$invalid && submitted,
                      }"
                    />
                    <small
                      v-if="
                        (v$.form.email_perusahaan.$invalid && submitted) ||
                        v$.form.email_perusahaan.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.email_perusahaan.required.$message }}</small
                    >
                  </div>
                  <div class="field col-3">
                    <label>NPWP</label>
                    <InputText
                      v-model="form.npwp"
                      type="text"
                      maxlength="150"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-3">
                    <label>kota</label>
                    <InputText
                      v-model="form.kota"
                      type="text"
                      maxlength="150"
                      class="w-full"
                      :class="{
                        'p-invalid': v$.form.kota.$invalid && submitted,
                      }"
                    />
                    <small
                      v-if="
                        (v$.form.kota.$invalid && submitted) ||
                        v$.form.kota.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.kota.required.$message }}</small
                    >
                  </div>
                  <div class="field col-3">
                    <label>kode pos</label>
                    <InputText
                      v-model="form.kode_pos"
                      type="text"
                      maxlength="150"
                      class="w-full"
                      :class="{
                        'p-invalid': v$.form.kode_pos.$invalid && submitted,
                      }"
                    />
                    <small
                      v-if="
                        (v$.form.kode_pos.$invalid && submitted) ||
                        v$.form.kode_pos.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.kode_pos.required.$message }}</small
                    >
                  </div>
                  <div class="field col-12">
                    <label>Alamat</label>
                    <Textarea
                      v-model="form.alamat"
                      :class="{
                        'p-invalid': v$.form.alamat.$invalid && submitted,
                      }"
                      maxlength="150"
                      class="w-full"
                    />
                    <small
                      v-if="
                        (v$.form.alamat.$invalid && submitted) ||
                        v$.form.alamat.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.alamat.required.$message }}</small
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-content-end">
              <Button
                :loading="isLoadingSave"
                type="submit"
                :label="form.id > 0 ? 'Update' : 'Simpan'"
                icon="pi pi-check"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import ProfileService from '@/services/ProfileService'
import errorHandler from '@/helpers/error-handler'

export default {
  name: 'IdentitasPerusahaan',
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      profileService: null,
      form: {
        id:0,
        nama: '',
        telepon_1: '',
        telepon_2: '',
        email_perusahaan: '',
        npwp: '',
        kota: '',
        kode_pos: '',
        alamat: '',
      },
      dialog: false,
      submitted: false,
      isLoading: false,
      isLoadingSave: false,
      disableSave: false,
    }
  },
  created() {
    this.profileService = new ProfileService()
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      this.profileService
        .get()
        .then((res) => {
          if (res.data.status === 200) {
            if(res.data.data){
              this.form = res.data.data
            }
          }
        })
        .catch((err) => {
          errorHandler(err, 'Identitas perusahaan', this)
        })
        .finally(() => (this.isLoading = false))
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }
      this.isLoadingSave = true

      if (this.form.id === 0) {
        this.profileService
          .add(this.form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Identitas perusahaan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
            }
          })
          .catch((err) => {
            errorHandler(err, 'Identitas perusahaan', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.profileService
          .update(this.form.id,this.form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Identitas perusahaan',
                detail: 'Data berhasil diupdate.',
                life: 3000,
              })
              this.loadData()
            }
          })
          .catch((err) => {
            errorHandler(err, 'Identitas perusahaan', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
  },
  validations() {
    return {
      form: {
        nama: {
          required: helpers.withMessage(
            'Nama perusahaan harus diisi.',
            required
          ),
        },
        telepon_1: {
          required: helpers.withMessage('No Telepon 1 harus diisi.', required),
        },
        email_perusahaan: {
          required: helpers.withMessage('Email harus diisi.', required),
        },
        kota: {
          required: helpers.withMessage('kota harus diisi.', required),
        },
        kode_pos: {
          required: helpers.withMessage('Kode Pos harus diisi.', required),
        },
        alamat: {
          required: helpers.withMessage('Alamat harus diisi.', required),
        },
      },
    }
  },
}
</script>
